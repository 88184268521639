// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-chip-js": () => import("./../../../src/pages/chip.js" /* webpackChunkName: "component---src-pages-chip-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tftb-2021-js": () => import("./../../../src/pages/TFTB2021.js" /* webpackChunkName: "component---src-pages-tftb-2021-js" */),
  "component---src-pages-the-boy-js": () => import("./../../../src/pages/theBoy.js" /* webpackChunkName: "component---src-pages-the-boy-js" */)
}

